/* eslint-disable react/display-name */
import React, { memo, useEffect, useRef, useState } from 'react';
import { TableSkeletonCol6 } from 'components/SkeletonLoader';
import { getCurrencySymbol } from 'containers/MyProfilePage/components/utils';
import { currencyData, editNoteIcon } from 'containers/App/constants';
import { Button, Selects } from 'components';
import SVG from 'react-inlinesvg';
import { CombinedFields } from 'containers/Auth/PersonalDetails/style';
import { Field } from 'redux-form';
import { renderField } from 'utils/Fields';
import { InvitedTalentRateField } from './invitedTalentRateField';

export const DEFAULT_PAGE_NO = 1;
export const DEFAULT_PAGE_SIZE = 20;

export const CHANGE_TALENT_STATUS = 'Notchup/ClientTalents/CHANGE_TALENT_STATUS';
export const CHANGE_TALENT_RATE = 'Notchup/ClientTalents/CHANGE_TALENT_RATE';
export const key = 'ChangeTalentStatus';

const DefaultRateSection = memo(({ talentItem, setUpdateTalentRateId }) => (
  <React.Fragment>
    {getCurrencySymbol(currencyData, 'code', talentItem.currency)}
    {talentItem.ratePerHour}
    {talentItem?.clientOnboarded && (
      <Button className="btn-icon btn-link ps-1" onClick={() => setUpdateTalentRateId(talentItem.talentUserId)}>
        <SVG src={editNoteIcon} className="me-1" />
      </Button>
    )}
  </React.Fragment>
));

export const columns = ({
  setUpdateTalentRateId,
  editTalentRate,
  talentsResponseDocs,
  updateTalentRate,
  hideColumns: { showProject = true } = {},
}) => [
  {
    name: 'Name',
    selector: 'name',
    style: {
      paddingLeft: '40px',
    },
  },
  {
    name: 'Role',
    selector: 'role',
  },
  ...(showProject
    ? [
        {
          name: 'Project',
          selector: 'project',
        },
      ]
    : []),
  {
    name: 'Rate',
    selector: 'rate',
    allowOverflow: true,
    minWidth: '200px',
    cell: (row, index) =>
      editTalentRate !== talentsResponseDocs?.[index]?.talentUserId ? (
        <DefaultRateSection talentItem={talentsResponseDocs[index]} setUpdateTalentRateId={setUpdateTalentRateId} />
      ) : (
        <InvitedTalentRateField
          talentItem={talentsResponseDocs[index]}
          updateTalentRate={updateTalentRate}
          setUpdateTalentRateId={setUpdateTalentRateId}
        />
      ),
  },
  {
    name: 'Allocation till',
    selector: 'allocation',
    allowOverflow: true,
  },
  {
    name: 'Status',
    selector: 'status',
    minWidth: '170px',
    allowOverflow: true,
    style: {
      paddingRight: '40px',
    },
  },
  {
    name: 'Actions',
    selector: 'action',
    allowOverflow: true,
    style: {
      // paddingRight: '40px',
    },
  },
];

export const LinearIndeterminate = () => (
  <div className="w-100 flex-column d-flex">
    <TableSkeletonCol6 cardCount={5} />
  </div>
);

export const clientTabItems = [
  {
    name: 'Composition',
    tabIndex: '1',
  },
  {
    name: 'Talents',
    tabIndex: '2',
  },
  {
    name: 'Saved',
    tabIndex: '3',
  },
];

export const talentSubTabItems = [
  {
    name: 'All',
    tabIndex: 'all',
    counterKey: 'allTalentCount',
  },
  {
    name: 'Notchup',
    tabIndex: 'notchup',
    counterKey: 'notchupTalentCount',
  },
  {
    name: 'Internal',
    tabIndex: 'internal',
    counterKey: 'internalTalentCount',
  },
];
